import React, { useState, useEffect } from 'react';
import classes from './SideBar.module.scss';
import { Link } from 'react-router-dom';
import Area from '../../SVG/Area';
import Docs from '../../SVG/Docs';
import Product from '../../SVG/Product';
import Deal from '../../SVG/Deal';
import Voucher from '../../SVG/Voucher';
import People from '../../SVG/People';
import Category from '../../SVG/Category';
import Requests from '../../SVG/Requests';
import AdsManager from '../../SVG/AdsManager';
import AdviceManager from '../../SVG/AdviceManager';
const SideBar = (props) => {
  const [change, setCahange] = useState(0);
  const [render, setRender] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const menuOptionsMain = [
    'Product',
    'Working Areas Serve',
    'Original Deal',
    'Cold Deal',
    'Vouchers',
    'Suppliers',
    'Product Requests',
    'Contact Requests',
    'Category Manager',
    'Marketing Manager',
    'Category',
    'Sub Category',
    'About',
    'Privacy Policy',
    'Terms & Conditions',
    'Delivery Terms',
    'Payment Policy',
    'Main Data',
  ];
  const menuOptionsSupplier = ['Original Deal', 'Cold Deal', 'Vouchers', 'Cashiers'];

  const menuOptionsCategory = [
    'Product',
    'Working Areas Serve',
    'Original Deal',
    'Cold Deal',
    'Vouchers',
    // "Suppliers",
    'Product Requests',
    'Contact Requests',
    // "Marketing Manager",
    'Category',
    'Sub Category',
  ];
  const menuOptionsMarketing = [
    'Ads',
    'Advices',
    // "Product Clients Requests",
    // "Business Clients Requests",
  ];
  useEffect(() => {
    if (localStorage.getItem('side') === 'Top Manager') {
      setMenuOptions([...menuOptionsMain]);
      setRender(true);
    } else if (localStorage.getItem('side') === 'Marketing Manager') {
      setMenuOptions([...menuOptionsMarketing]);
      setRender(true);
    } else if (localStorage.getItem('side') === 'Category Manager') {
      setMenuOptions([...menuOptionsCategory]);
      setRender(true);
    } else if (localStorage.getItem('side') === 'Supplier') {
      setMenuOptions([...menuOptionsSupplier]);
      setRender(true);
    }
    setCahange((prev) => prev + 1);
  }, []);

  return (
    <>
      {render && (
        <section
          style={{
            position: 'sticky',
            paddingBottom: '0',
            top: '0px',
          }}
          className={`  ${classes.sidebar}`}
        >
          <ul className="">
            <li className={`${classes.logo}`}>
              <svg width="auto" height="50" viewBox="0 0 148 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M89.7398 4.4769C90.8568 4.4769 91.7623 3.74146 91.7623 2.83425C91.7623 1.92703 90.8568 1.19159 89.7398 1.19159C88.6228 1.19159 87.7173 1.92703 87.7173 2.83425C87.7173 3.74146 88.6228 4.4769 89.7398 4.4769Z"
                  fill="#134074"
                />
                <path
                  d="M83.5909 4.4769C84.7079 4.4769 85.6134 3.74146 85.6134 2.83425C85.6134 1.92703 84.7079 1.19159 83.5909 1.19159C82.4739 1.19159 81.5684 1.92703 81.5684 2.83425C81.5684 3.74146 82.4739 4.4769 83.5909 4.4769Z"
                  fill="#134074"
                />
                <path
                  d="M57.4747 6.06821C58.5917 6.06821 59.4972 5.33277 59.4972 4.42555C59.4972 3.51834 58.5917 2.7829 57.4747 2.7829C56.3577 2.7829 55.4521 3.51834 55.4521 4.42555C55.4521 5.33277 56.3577 6.06821 57.4747 6.06821Z"
                  fill="#134074"
                />
                <path
                  d="M96.128 4.4769C97.245 4.4769 98.1505 3.74146 98.1505 2.83425C98.1505 1.92703 97.245 1.19159 96.128 1.19159C95.011 1.19159 94.1055 1.92703 94.1055 2.83425C94.1055 3.74146 95.011 4.4769 96.128 4.4769Z"
                  fill="#134074"
                />
                <path
                  d="M96.128 26.1791C97.245 26.1791 98.1505 25.4437 98.1505 24.5365C98.1505 23.6292 97.245 22.8938 96.128 22.8938C95.011 22.8938 94.1055 23.6292 94.1055 24.5365C94.1055 25.4437 95.011 26.1791 96.128 26.1791Z"
                  fill="#134074"
                />
                <path
                  d="M94.8628 6.91207C93.6003 6.71694 92.8599 7.52732 92.4603 8.40938C90.9158 11.8241 89.5037 15.2827 88.2069 18.7671C87.6773 20.1907 87.0473 21.4232 88.7143 21.8891C90.5898 22.4148 90.7148 21.4252 91.3693 19.8821C92.7545 16.6167 94.1886 13.1163 95.586 9.61399C96.2308 8.0012 96.3803 7.14503 94.8628 6.91207Z"
                  fill="#134074"
                />
                <path
                  d="M88.6719 6.91207C87.4093 6.71694 86.669 7.52732 86.2694 8.40938C84.7249 11.8241 83.3128 15.2827 82.0159 18.7671C81.4864 20.1907 80.8563 21.4232 82.5234 21.8891C84.3988 22.4148 84.5239 21.4252 85.1784 19.8821C86.5636 16.6167 87.9977 13.1163 89.3951 9.61399C90.0399 8.0012 90.1894 7.14503 88.6719 6.91207Z"
                  fill="#134074"
                />
                <path
                  d="M82.4824 6.91207C81.2199 6.71694 80.4795 7.52732 80.0799 8.40938C78.5354 11.8241 77.1234 15.2827 75.8265 18.7671C75.2969 20.1907 74.6669 21.4232 76.334 21.8891C78.2094 22.4148 78.3344 21.4252 78.989 19.8821C80.3741 16.6167 81.8083 13.1163 83.2057 9.61399C83.8504 8.0012 84 7.14503 82.4824 6.91207Z"
                  fill="#134074"
                />
                <path
                  d="M75.0568 8.45696C73.9242 8.28174 73.2598 9.01049 72.9019 9.80095C71.5143 12.8672 70.2468 15.9714 69.0823 19.0994C68.6067 20.3777 68.0429 21.4827 69.5359 21.9029C71.2201 22.3747 71.3329 21.4867 71.9188 20.1009C73.1617 17.17 74.4488 14.0261 75.704 10.8841C76.285 9.43658 76.4174 8.66802 75.0568 8.45696Z"
                  fill="#134074"
                />
                <path
                  d="M102.289 1.23196C101.026 0.963159 100.286 2.08016 99.8861 3.29274C98.3416 7.99173 96.9295 12.7505 95.6326 17.541C95.1031 19.4983 94.473 21.1947 96.1401 21.8378C98.0155 22.5626 98.1406 21.2007 98.7951 19.0782C100.18 14.5863 101.614 9.76979 103.012 4.95332C103.657 2.73125 103.806 1.55452 102.289 1.23196Z"
                  fill="#134074"
                />
                <path
                  d="M67.9964 12.9829C67.1261 12.7519 66.5329 13.4428 66.1774 14.2154C64.7923 17.202 63.4978 20.2345 62.2819 23.2928C61.7842 24.5432 61.235 25.6164 62.3652 26.1102C63.64 26.6657 63.8141 25.7856 64.4074 24.4337C65.6675 21.5745 66.9815 18.5062 68.271 15.4359C68.8643 14.0203 69.0457 13.2616 67.9964 12.9829Z"
                  fill="#134074"
                />
                <path
                  d="M63.2457 8.45696C62.1131 8.28174 61.4488 9.01049 61.0908 9.80095C59.7033 12.8672 58.4358 15.9714 57.2713 19.0994C56.7957 20.3777 56.2318 21.4827 57.7248 21.9029C59.4091 22.3747 59.5218 21.4867 60.1078 20.1009C61.3507 17.17 62.6378 14.0261 63.893 10.8841C64.474 9.43658 64.6088 8.66802 63.2457 8.45696Z"
                  fill="#134074"
                />
                <path
                  d="M58.9248 22.8212C58.4761 22.8212 58.1108 23.1179 58.1108 23.4823C58.1108 23.8466 58.4761 24.1433 58.9248 24.1433C59.3734 24.1433 59.7387 23.8466 59.7387 23.4823C59.7387 23.1159 59.3734 22.8212 58.9248 22.8212Z"
                  fill="#134074"
                />
                <path
                  d="M56.3295 22.8212C55.8809 22.8212 55.5156 23.1179 55.5156 23.4823C55.5156 23.8466 55.8809 24.1433 56.3295 24.1433C56.7782 24.1433 57.1435 23.8466 57.1435 23.4823C57.1435 23.1159 56.7806 22.8212 56.3295 22.8212Z"
                  fill="#134074"
                />
                <path
                  d="M56.5622 8.45696C55.4295 8.28174 54.7652 9.01049 54.4072 9.80095C53.0197 12.8672 51.7522 15.9714 50.5877 19.0994C50.1121 20.3777 49.5483 21.4827 51.0412 21.9029C52.7255 22.3747 52.8382 21.4867 53.4242 20.1009C54.6671 17.17 55.9542 14.0261 57.2094 10.8841C57.7904 9.43658 57.9228 8.66802 56.5622 8.45696Z"
                  fill="#134074"
                />
                <path
                  d="M51.7495 1.23196C50.487 0.963159 49.7466 2.08016 49.347 3.29274C47.8025 7.99173 46.3904 12.7505 45.0936 17.541C44.564 19.4983 43.934 21.1947 45.601 21.8378C47.4765 22.5626 47.6015 21.2007 48.2561 19.0782C49.6412 14.5863 51.0754 9.76979 52.4727 4.95332C53.1175 2.73125 53.267 1.55452 51.7495 1.23196Z"
                  fill="#134074"
                />
                <path
                  d="M75.7407 5.44363C75.7407 5.808 76.106 6.10467 76.5546 6.10467C77.0033 6.10467 77.3686 5.808 77.3686 5.44363C77.3686 5.07926 77.0033 4.78259 76.5546 4.78259C76.1036 4.78259 75.7407 5.07926 75.7407 5.44363Z"
                  fill="#134074"
                />
                <path
                  d="M73.1445 5.44363C73.1445 5.808 73.5098 6.10467 73.9584 6.10467C74.4071 6.10467 74.7724 5.808 74.7724 5.44363C74.7724 5.07926 74.4071 4.78259 73.9584 4.78259C73.5098 4.78259 73.1445 5.07926 73.1445 5.44363Z"
                  fill="#134074"
                />
                <path
                  d="M73.4284 2.08962C73.7888 2.00401 74.1197 1.91241 74.4066 1.82281C74.5414 1.781 74.664 1.73919 74.7743 1.69937L78.3805 0.480814L78.4786 0.446969C78.9444 0.287681 79.2288 0.142331 79.1994 0.0606955C79.1503 -0.0766903 78.204 0.0268434 77.0861 0.293651C76.8067 0.359357 76.5419 0.431044 76.3041 0.500733H76.3016C76.0222 0.582368 75.8015 0.663995 75.5882 0.741648C74.8822 1.00248 73.2617 1.54406 72.411 1.81485C72.1879 1.88653 71.9673 1.95622 71.7981 2.02192C71.4745 2.14736 71.2906 2.25488 71.3152 2.32059C71.3642 2.45797 72.3105 2.35444 73.4284 2.08962Z"
                  fill="#134074"
                />
                <path
                  d="M8.55604 36.4205C8.66146 36.4205 8.82571 36.4126 9.0488 36.3947C9.27189 36.3767 9.43615 36.3688 9.54157 36.3688C11.493 36.3688 13.3145 36.8427 15.0012 37.7904C16.6878 38.7382 17.6268 39.9547 17.818 41.4361L3.90544 41.4879C3.81964 42.0753 3.77796 42.5651 3.77796 42.9613C3.77796 45.6154 4.74388 47.4771 6.6757 48.5463C7.80096 49.1158 8.97771 49.4005 10.2108 49.4005C11.4636 49.4005 12.6845 49.1198 13.8735 48.5603C15.0625 48.0008 15.9843 47.2641 16.6437 46.3502C16.6658 46.3163 16.9943 45.703 17.6317 44.5144L18.1097 44.9026C17.769 45.9022 17.1904 46.7942 16.374 47.5787C15.5577 48.3631 14.5648 48.9704 13.3979 49.4025L10.4683 49.9978H9.92646C7.93089 49.9978 6.16823 49.6056 4.64091 48.8211C3.11359 48.0366 2.03 46.9037 1.3926 45.4203C1.0739 44.3511 0.914551 43.6024 0.914551 43.1704C0.914551 42.1011 1.23325 41.0718 1.87066 40.0802C2.50806 39.0886 3.41023 38.2484 4.57717 37.5595C5.91327 36.9203 7.23955 36.542 8.55604 36.4205ZM8.74726 36.9124C7.49452 37.0338 6.45506 37.4778 5.62644 38.2444C4.79781 39.011 4.30995 39.9129 4.16286 40.9463H14.7021V40.5322C14.7021 39.4629 14.1775 38.5928 13.1257 37.9198C12.074 37.2469 10.8605 36.9104 9.48028 36.9104H8.74726V36.9124Z"
                  fill="#134074"
                />
                <path
                  d="M30.1739 36.4981C31.3408 36.6534 32.4979 36.9381 33.6453 37.3523C33.6232 37.9556 33.3486 38.7401 32.8166 39.7058C32.3925 38.8615 31.6178 38.1666 30.4926 37.6251C29.3673 37.0815 28.2004 36.8107 26.9893 36.8107C25.4178 36.8107 24.2092 37.2069 23.3585 37.9994C22.8265 38.5509 22.5618 39.1204 22.5618 39.7058C22.5618 40.3947 23.0178 40.99 23.9298 41.4898C26.1362 42.0075 28.3131 42.3002 30.4558 42.3699C31.6031 42.4216 32.5617 42.776 33.3364 43.4311C34.1111 44.0862 34.4984 44.8448 34.4984 45.7069C34.4984 46.1888 34.3611 46.6726 34.0841 47.1545C32.5984 49.052 30.0611 49.9997 26.4745 49.9997C25.8812 49.9997 25.0526 49.9659 23.991 49.8962C21.613 49.6891 19.9362 49.2929 18.9604 48.7075L20.0759 46.3023C20.6913 48.0086 22.5593 49.052 25.6802 49.4303L28.8329 49.3526C29.6615 49.2491 30.4362 48.8967 31.1569 48.2934C31.8777 47.6901 32.2405 47.0609 32.2405 46.4058C32.2405 45.8204 31.9537 45.3207 31.38 44.9065C30.9338 44.6317 30.3283 44.4326 29.5659 44.3112L24.4715 43.7158C23.3242 43.5088 22.3338 43.1086 21.4954 42.5132C20.6569 41.9179 20.2377 41.2071 20.2377 40.3808C20.2377 40.1737 20.3235 39.8292 20.4927 39.3454C20.8114 38.6206 21.363 38.0313 22.1475 37.5733C22.932 37.1173 23.7753 36.8027 24.6775 36.6295C25.5796 36.4583 26.5505 36.3707 27.5899 36.3707C27.9282 36.3707 28.4063 36.3926 29.0216 36.4344C29.6443 36.4762 30.0268 36.4981 30.1739 36.4981Z"
                  fill="#134074"
                />
                <path
                  d="M39.6637 31.6353V39.8326C40.0461 38.7634 40.8625 37.9192 42.1152 37.2979C43.368 36.6767 44.7261 36.3681 46.1897 36.3681C47.4204 36.3681 48.5628 36.5871 49.6121 37.0271C50.6638 37.4672 51.4434 38.1083 51.9533 38.9525C52.3554 39.8844 52.5785 40.6947 52.6226 41.3837V48.2888C52.728 48.5656 52.9192 48.8284 53.1963 49.0773C53.4708 49.3281 53.7699 49.4954 54.0886 49.581L48.58 49.6328C49.3449 49.3222 49.8205 48.8563 50.0117 48.237C50.2446 46.1862 50.3623 44.7287 50.3623 43.8666C50.3623 42.0228 50.0754 40.4021 49.5018 39.0043C49.0776 38.401 48.4721 37.9271 47.6876 37.5827C46.9031 37.2382 46.0843 37.065 45.2361 37.065C43.9637 37.065 42.9218 37.3577 42.1152 37.945C41.4778 38.4627 40.9998 38.9267 40.6835 39.3408C39.9628 40.5992 39.5999 42.1602 39.5999 44.0219C39.5999 44.4878 39.7176 45.8158 39.9505 48.0041C39.9505 48.7627 40.4065 49.2704 41.3185 49.5293L41.2547 49.6846L35.7461 49.6328V49.4257C35.9153 49.4098 36.1923 49.3321 36.5747 49.1927C37.0846 48.7109 37.3715 48.2271 37.4352 47.7452V34.0146C37.4352 33.5328 37.2342 33.1525 36.8297 32.8777C36.7022 32.8438 36.457 32.7582 36.0967 32.6189L36.0648 32.5671C36.0427 32.5332 36.0329 32.5074 36.0329 32.4894L39.6637 31.6353Z"
                  fill="#134074"
                />
                <path
                  d="M59.848 33.6777L59.9755 33.8848V37.3493H63.0326L62.7776 37.9964L59.9755 38.0482V46.6079C60.103 47.4183 60.5492 48.2287 61.3116 49.0391C61.8411 49.2641 62.2138 49.3935 62.4271 49.4273L62.2996 49.6344C61.7676 49.7379 61.1743 49.7897 60.5173 49.7897C59.9436 49.7897 59.37 49.7379 58.7988 49.6344L58.3207 49.4014C57.9162 48.8499 57.7152 48.2984 57.7152 47.7468V38.0502L55.3911 37.9984V37.7137C57.3646 37.1781 58.8503 35.8341 59.848 33.6777Z"
                  fill="#134074"
                />
                <path
                  d="M70.578 36.3688H71.5978C72.446 36.3688 73.2845 36.4385 74.1131 36.5759C74.9417 36.7132 75.7409 36.9303 76.5181 37.223C77.2928 37.5157 77.913 37.9338 78.3813 38.4774C78.847 39.0209 79.0824 39.6541 79.0824 40.3789C78.9549 42.2923 78.8912 43.258 78.8912 43.2759C78.8912 44.6199 79.0383 46.1968 79.3374 48.0087C79.4207 48.5941 79.8252 49.1556 80.546 49.6892H77.6164C77.1285 48.8609 76.8834 48.0685 76.8834 47.3099C76.246 48.1899 75.3806 48.857 74.2896 49.3149C73.1962 49.7709 71.9925 49.9998 70.676 49.9998L67.6189 49.5339C66.5353 49.3109 65.6454 48.8749 64.9443 48.2278C64.2431 47.5806 63.8926 46.8619 63.8926 46.0694C63.8926 45.4323 64.1794 44.7672 64.7531 44.0783C66.3882 42.5093 69.3914 41.7248 73.7625 41.7248C74.0812 41.7248 74.5912 41.7328 75.2898 41.7507C75.991 41.7686 76.4788 41.7766 76.7534 41.7766C76.7117 40.6556 76.5303 39.7079 76.2116 38.9313C75.8929 38.1548 75.2555 37.5774 74.3019 37.1991C73.4732 36.8725 72.5711 36.7073 71.5954 36.7073C70.323 36.7073 69.1389 37.0039 68.0455 37.5993C66.9521 38.1946 66.1823 38.9751 65.7361 39.9388L64.8119 37.7148L64.8756 37.5077C66.832 36.8685 68.7319 36.4902 70.578 36.3688ZM76.5647 42.3162H74.7186C69.8989 42.3162 67.288 43.4631 66.8859 45.7548C66.8859 45.8245 66.8638 45.936 66.8222 46.0913C66.7805 46.2466 66.7584 46.3502 66.7584 46.4019C66.7584 47.3338 67.2242 48.0705 68.1583 48.612C69.0923 49.1556 70.1734 49.4264 71.4066 49.4264C71.6615 49.4264 72.1592 49.3846 72.902 49.297C73.4953 49.1417 74.0592 48.9266 74.5887 48.6499C75.7998 47.9609 76.5205 46.8997 76.7534 45.4701V42.573L76.5647 42.3162Z"
                  fill="#134074"
                />
                <path
                  d="M91.5587 36.6013L90.5069 38.9548C90.1024 38.6103 89.595 38.4371 88.9796 38.4371C88.4697 38.4371 87.9867 38.5785 87.5308 38.8632C87.0748 39.1479 86.7193 39.5222 86.4643 39.9882C85.8907 40.9718 85.594 41.4875 85.572 41.5392V47.0984C85.572 47.632 85.7043 48.1278 85.9691 48.5857C86.2339 49.0417 86.6531 49.3224 87.2268 49.426H87.5136L87.4498 49.6848H81.4632L81.3994 49.4777C81.8235 49.41 82.152 49.3145 82.3874 49.193C82.8336 48.7112 83.1302 47.8649 83.2798 46.6583V38.9528C83.2798 38.901 83.2748 38.8075 83.265 38.6681C83.2552 38.5307 83.2479 38.4451 83.2479 38.4092C82.9709 37.9612 82.4511 37.6765 81.6887 37.555L85.6357 36.7009V39.8807C86.1236 39.0364 86.8713 38.2778 87.8813 37.6048C88.8889 36.9318 89.9357 36.5953 91.0169 36.5953H91.5587V36.6013Z"
                  fill="#134074"
                />
                <path
                  d="M100.315 36.4205C100.42 36.4205 100.585 36.4126 100.808 36.3947C101.031 36.3767 101.195 36.3688 101.3 36.3688C103.252 36.3688 105.073 36.8427 106.76 37.7904C108.447 38.7382 109.386 39.9547 109.577 41.4361L95.6642 41.4879C95.5784 42.0753 95.5368 42.5651 95.5368 42.9613C95.5368 45.6154 96.5027 47.4771 98.4345 48.5463C99.5598 49.1158 100.737 49.4005 101.97 49.4005C103.222 49.4005 104.441 49.1198 105.632 48.5603C106.821 48.0008 107.743 47.2641 108.403 46.3502C108.422 46.3163 108.753 45.703 109.39 44.5144L109.869 44.9026C109.528 45.9022 108.949 46.7942 108.133 47.5787C107.316 48.3631 106.324 48.9704 105.157 49.4025L102.227 49.9978H101.685C99.6897 49.9978 97.927 49.6056 96.3997 48.8211C94.8724 48.0366 93.7888 46.9037 93.1514 45.4203C92.8327 44.3511 92.6733 43.6024 92.6733 43.1704C92.6733 42.1011 92.992 41.0718 93.6295 40.0802C94.2669 39.0886 95.169 38.2484 96.336 37.5595C97.6721 36.9203 98.9984 36.542 100.315 36.4205ZM100.506 36.9124C99.2533 37.0338 98.2139 37.4778 97.3852 38.2444C96.5566 39.011 96.0687 39.9129 95.9216 40.9463H106.461V40.5322C106.461 39.4629 105.936 38.5928 104.885 37.9198C103.833 37.2469 102.619 36.9104 101.239 36.9104H100.506V36.9124Z"
                  fill="#134074"
                />
                <path
                  d="M121.327 36.3688C122.876 36.3688 124.261 36.7053 125.482 37.3763C126.703 38.0493 127.441 38.9711 127.696 40.1439C127.929 43.2978 128.046 45.4362 128.046 46.5572V48.2895C128.279 48.8749 128.767 49.3408 129.51 49.6853H123.874C124.744 49.2552 125.274 48.5224 125.465 47.4871C125.548 46.3143 125.592 45.3924 125.592 44.7194C125.592 43.2201 125.475 41.6591 125.242 40.0384C125.051 39.1065 124.514 38.3798 123.634 37.8541C122.754 37.3285 121.751 37.0657 120.626 37.0657C119.267 37.0657 118.088 37.446 117.09 38.2026C115.669 39.5983 114.958 41.6153 114.958 44.2535C114.958 45.0121 115.053 46.2625 115.244 48.0028C115.308 48.624 115.742 49.1317 116.551 49.5279C116.571 49.5459 116.6 49.5718 116.63 49.6056C116.661 49.6395 116.688 49.6653 116.71 49.6833H111.042C112.209 49.08 112.793 48.2178 112.793 47.0968V39.236C112.793 39.1683 112.766 39.0388 112.714 38.8477C112.661 38.6585 112.634 38.5371 112.634 38.4853C112.315 38.107 111.923 37.8123 111.457 37.6053L111.521 37.3464L115.056 36.6993V39.9309C115.48 38.8099 116.259 37.9358 117.394 37.3066C118.53 36.6834 119.841 36.3688 121.327 36.3688Z"
                  fill="#134074"
                />
                <path
                  d="M137.119 36.3688H138.139C138.988 36.3688 139.826 36.4385 140.655 36.5759C141.483 36.7132 142.282 36.9303 143.057 37.223C143.832 37.5157 144.452 37.9338 144.92 38.4774C145.386 39.0209 145.621 39.6541 145.621 40.3789C145.494 42.2923 145.43 43.258 145.43 43.2759C145.43 44.6199 145.577 46.1968 145.876 48.0087C145.96 48.5941 146.364 49.1556 147.085 49.6892H144.155C143.668 48.8609 143.422 48.0685 143.422 47.3099C142.785 48.1899 141.92 48.857 140.829 49.3149C139.735 49.7709 138.532 49.9998 137.215 49.9998L134.158 49.5339C133.074 49.3109 132.184 48.8749 131.483 48.2278C130.782 47.5806 130.432 46.8619 130.432 46.0694C130.432 45.4323 130.718 44.7672 131.292 44.0783C132.927 42.5093 135.93 41.7248 140.302 41.7248C140.62 41.7248 141.13 41.7328 141.829 41.7507C142.53 41.7686 143.018 41.7766 143.292 41.7766C143.251 40.6556 143.069 39.7079 142.751 38.9313C142.432 38.1548 141.795 37.5774 140.841 37.1991C140.012 36.8725 139.11 36.7073 138.134 36.7073C136.862 36.7073 135.678 37.0039 134.585 37.5993C133.491 38.1946 132.721 38.9751 132.275 39.9388L131.351 37.7148L131.415 37.5077C133.371 36.8685 135.271 36.4902 137.119 36.3688ZM143.104 42.3162H141.258C136.438 42.3162 133.829 43.4631 133.425 45.7548C133.425 45.8245 133.403 45.936 133.361 46.0913C133.32 46.2466 133.298 46.3502 133.298 46.4019C133.298 47.3338 133.763 48.0705 134.697 48.612C135.631 49.1556 136.713 49.4264 137.946 49.4264C138.201 49.4264 138.698 49.3846 139.441 49.297C140.034 49.1417 140.598 48.9266 141.128 48.6499C142.339 47.9609 143.06 46.8997 143.292 45.4701V42.573L143.104 42.3162Z"
                  fill="#134074"
                />
              </svg>
            </li>
            <div
              style={{
                height: `${window.innerHeight - 146}px`,
                overflow: 'scroll',
              }}
            >
              {menuOptions.length > 0 &&
                menuOptions.map((option, index) => (
                  <li key={index} className=" w-auto   ">
                    <Link
                      style={{ textDecoration: 'none', color: 'unset' }}
                      onClick={() => {
                        setCahange((prev) => prev + 1);
                      }}
                      to={
                        (option === 'Working Areas Serve' && `/account/area-serve`) ||
                        (option === 'Ads' && `/account/ad`) ||
                        (option === 'Cashiers' && `/supplier/cashiers`) ||
                        (option === 'Advices' && `/account/advice`) ||
                        (option === 'Product' && `/account/product`) ||
                        (option === 'Original Deal' &&
                          (localStorage.getItem('side') === 'Supplier' ? `/supplier/original-deal` : '/account/original-deal')) ||
                        (option === 'Cold Deal' && (localStorage.getItem('side') === 'Supplier' ? `/supplier/cold-deal` : '/account/cold-deal')) ||
                        (option === 'Area Deal' && `/account/area-deal`) ||
                        (option === 'Contact Requests' && `/account/clients-requests`) ||
                        (option === 'Vouchers' && (localStorage.getItem('side') === 'Supplier' ? `/supplier/voucher` : `/account/voucher`)) ||
                        (option === 'Contact Requests' && `/account/clients-requests`) ||
                        (option === 'Product Requests' && `/account/product-requests`) ||
                        (option === 'Suppliers' && `/account/suppliers`) ||
                        (option === 'Category Manager' && `/account/category-manager`) ||
                        (option === 'Marketing Manager' && `/account/marketing-manager`) ||
                        (option === 'Category' && `/account/category`) ||
                        (option === 'Sub Category' && `/account/sub-category`) ||
                        (option === 'About' && `/account/about`) ||
                        (option === 'Privacy Policy' && `/account/privacy-policy`) ||
                        (option === 'Terms & Conditions' && `/account/terms-conditions`) ||
                        (option === 'Delivery Terms' && `/account/delivery-terms`) ||
                        (option === 'Payment Policy' && `/account/payment-policy`) ||
                        (option === 'Main Data' && `/account/main-data`)
                      }
                      // activeClassName={classes.active}
                    >
                      <div
                        style={{
                          position: 'relative',
                          left: '8px',
                        }}
                        className={`row  mx-0  align-items-center  ${classes.item}   ${
                          (option === 'Product' &&
                            window.location.pathname.startsWith('/account/product') &&
                            !window.location.pathname.startsWith('/account/product-') &&
                            classes.active) ||
                          (option === 'Cashiers' && window.location.pathname.startsWith('/supplier/cashiers') && classes.active) ||
                          (option === 'Vouchers' &&
                            window.location.pathname.startsWith(
                              localStorage.getItem('side') === 'Supplier' ? `/supplier/voucher` : '/account/voucher',
                            ) &&
                            classes.active) ||
                          (option === 'Ads' &&
                            window.location.pathname.startsWith('/account/ad') &&
                            !window.location.pathname.includes('ce') &&
                            classes.active) ||
                          (option === 'Original Deal' &&
                            window.location.pathname.startsWith(
                              localStorage.getItem('side') === 'Supplier' ? `/supplier/original-deal` : '/account/original-deal',
                            ) &&
                            // !window.location.pathname.includes("ce") &&
                            classes.active) ||
                          (option === 'Cold Deal' &&
                            window.location.pathname.startsWith(
                              localStorage.getItem('side') === 'Supplier' ? `/supplier/cold-deal` : `/account/cold-deal`,
                            ) &&
                            classes.active) ||
                          (option === 'Contact Requests' && window.location.pathname.startsWith('/account/clients-requests') && classes.active) ||
                          (option === 'Contact Requests' && window.location.pathname.startsWith('/account/clients-requests') && classes.active) ||
                          (option === 'Product Requests' && window.location.pathname === '/account/product-requests' && classes.active) ||
                          (option === 'Advices' && window.location.pathname.startsWith('/account/advice') && classes.active) ||
                          (option === 'Category Manager' && window.location.pathname.startsWith('/account/category-manager') && classes.active) ||
                          (option === 'Marketing Manager' && window.location.pathname.startsWith('/account/marketing-manager') && classes.active) ||
                          (option === 'Suppliers' && window.location.pathname.startsWith('/account/supplier') && classes.active) ||
                          (option === 'Working Areas Serve' && window.location.pathname.startsWith('/account/area-serve') && classes.active) ||
                          (option === 'Privacy Policy' && window.location.pathname.startsWith('/account/privacy-policy') && classes.active) ||
                          (option === 'Terms & Conditions' && window.location.pathname.startsWith('/account/terms-conditions') && classes.active) ||
                          (option === 'About' && window.location.pathname.startsWith('/account/about') && classes.active) ||
                          (option === 'Delivery Terms' && window.location.pathname.startsWith('/account/delivery-terms') && classes.active) ||
                          (option === 'Payment Policy' && window.location.pathname.startsWith('/account/payment-policy') && classes.active) ||
                          (option === 'Category' && window.location.pathname === '/account/category' && classes.active) ||
                          (option === 'Sub Category' && window.location.pathname.startsWith('/account/sub-category') && classes.active) ||
                          (option === 'Main Data' && window.location.pathname.startsWith('/account/main-data') && classes.active)
                        }    `}
                      >
                        <div className="w-auto d-inline px-0 mx-0">
                          {(option === 'Working Areas Serve' &&
                            (window.location.pathname.startsWith('/account/area-serve') ? <Area color="black" /> : <Area />)) ||
                            (option === 'Product' &&
                              (window.location.pathname.startsWith('/account/product') &&
                              !window.location.pathname.startsWith('/account/product-') ? (
                                <Product color="black" />
                              ) : (
                                <Product />
                              ))) ||
                            (option === 'Contact Requests' &&
                              (window.location.pathname.startsWith('/account/clients-requests') ? <Requests color="black" /> : <Requests />)) ||
                            (option === 'Product Requests' &&
                              (window.location.pathname.startsWith('/account/product-requests') ? <Requests color="black" /> : <Requests />)) ||
                            (option === 'Original Deal' &&
                              (window.location.pathname.startsWith(
                                localStorage.getItem('side') === 'Supplier' ? `/supplier/original-deal` : '/account/original-deal',
                              ) ? (
                                <Deal color="black" />
                              ) : (
                                <Deal />
                              ))) ||
                            (option === 'Cold Deal' &&
                              (window.location.pathname.startsWith(
                                localStorage.getItem('side') === 'Supplier' ? `/supplier/cold-deal` : '/account/cold-deal',
                              ) ? (
                                <Deal color="black" />
                              ) : (
                                <Deal />
                              ))) ||
                            (option === 'Cold Deal' &&
                              (window.location.pathname.startsWith('/account/cold-deal') ? <Deal color="black" /> : <Deal />)) ||
                            (option === 'Working Areas Deal' &&
                              (window.location.pathname.startsWith('/account/area-deal') ? <Deal color="black" /> : <Deal />)) ||
                            (option === 'Vouchers' &&
                              (window.location.pathname.startsWith(
                                localStorage.getItem('side') === 'Supplier' ? '/supplier/voucher' : '/account/voucher',
                              ) ? (
                                <Voucher color="black" />
                              ) : (
                                <Voucher />
                              ))) ||
                            (option === 'Suppliers' &&
                              (window.location.pathname.startsWith('/account/suppliers') ? <People color="black" /> : <People />)) ||
                            (option === 'Cashiers' &&
                              (window.location.pathname.startsWith('/supplier/cashiers') ? <People color="black" /> : <People />)) ||
                            (option === 'Category Manager' &&
                              (window.location.pathname.startsWith('/account/category-manager') ? <People color="black" /> : <People />)) ||
                            (option === 'Marketing Manager' &&
                              (window.location.pathname.startsWith('/account/marketing-manager') ? <People color="black" /> : <People />)) ||
                            (option === 'Category' &&
                              (window.location.pathname === '/account/category' ? <Category color="black" /> : <Category />)) ||
                            (option === 'Sub Category' &&
                              (window.location.pathname.startsWith('/account/sub-category') ? <Category color="black" /> : <Category />)) ||
                            (option === 'Contact Requests' &&
                              (window.location.pathname.startsWith('/account/clients-requests') ? <Docs color="black" /> : <Docs />)) ||
                            (option === 'Privacy Policy' &&
                              (window.location.pathname.startsWith('/account/privacy-policy') ? <Docs color="black" /> : <Docs />)) ||
                            (option === 'Terms & Conditions' &&
                              (window.location.pathname.startsWith('/account/terms-conditions') ? <Docs color="black" /> : <Docs />)) ||
                            (option === 'Delivery Terms' &&
                              (window.location.pathname.startsWith('/account/delivery-terms') ? <Docs color="black" /> : <Docs />)) ||
                            (option === 'Payment Policy' &&
                              (window.location.pathname.startsWith('/account/payment-policy') ? <Docs color="black" /> : <Docs />)) ||
                            (option === 'Main Data' &&
                              (window.location.pathname.startsWith('/account/main-data') ? <Docs color="black" /> : <Docs />)) ||
                            (option === 'Ads' &&
                              (window.location.pathname.startsWith('/account/ad') && !window.location.pathname.includes('ce') ? (
                                <AdsManager color="black" />
                              ) : (
                                <AdsManager color="#134074" />
                              ))) ||
                            (option === 'Advices' &&
                              (window.location.pathname.startsWith('/account/advice') ? (
                                <AdviceManager color="black" />
                              ) : (
                                <AdviceManager color="#134074" />
                              ))) ||
                            (option === 'About' && (window.location.pathname.startsWith('/account/about') ? <Docs color="black" /> : <Docs />))}
                        </div>
                        <div className={`w-auto d-inline ${classes.option}`}>{option}</div>
                      </div>
                    </Link>
                  </li>
                ))}
            </div>
          </ul>
        </section>
      )}
    </>
  );
};

export default SideBar;
